import * as React from "react";
import Layout from "../components/layout";
import BannerImage from "../components/master/BannerImage";
import heroImage from "../assets/newsletter/newsletter-hero.jpg";
import NewsletterContent from "../components/newsletter/newsletterContent";

const Newsletters = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />
      <NewsletterContent/>
    </Layout>
  );
};

export default Newsletters;
