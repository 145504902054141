import * as React from "react";
import Layout from "../../components/layout";
import BannerImage from "../../components/master/BannerImage";
import heroImage from "../../assets/newsletter/newsletter-hero.jpg";
import ContentWrapper from "../../components/master/ContentWrapper";
import PageTitle from "../../components/master/PageTittle";
import "./newsletterStyle.css";


// import term1_week1 from "../../assets/newsletter/term1_week1.pdf"
import term1_week2 from "../../assets/newsletter/term1_week2.pdf"
// import term1_week3 from "../../assets/newsletter/term1_week3.pdf"
import term1_week4 from "../../assets/newsletter/term1_week4.pdf"
import term1_week5 from "../../assets/newsletter/term1_week5.pdf"
import term1_week6 from "../../assets/newsletter/term1_week6.pdf"
// import term1_week7 from "../../assets/newsletter/term1_week7.pdf"
import term1_week8 from "../../assets/newsletter/term1_week8.pdf"
import term1_week9 from "../../assets/newsletter/term1_week9.pdf"
// import term1_week10 from "../../assets/newsletter/term1_week10.pdf"

// import term2_week1 from "../../assets/newsletter/term2_week1.pdf"
// import term2_week2 from "../../assets/newsletter/term2_week2.pdf"
// import term2_week3 from "../../assets/newsletter/term2_week3.pdf"
// import term2_week4 from "../../assets/newsletter/term2_week4.pdf"
// import term2_week5 from "../../assets/newsletter/term2_week5.pdf"
// import term2_week6 from "../../assets/newsletter/term2_week6.pdf"
// import term2_week7 from "../../assets/newsletter/term2_week7.pdf"
// import term2_week8 from "../../assets/newsletter/term2_week8.pdf"
// import term2_week9 from "../../assets/newsletter/term2_week9.pdf"
// import term2_week10 from "../../assets/newsletter/term2_week10.pdf"
//
// import term3_week1 from "../../assets/newsletter/term3_week1.pdf"
// import term3_week2 from "../../assets/newsletter/term3_week2.pdf"
// import term3_week3 from "../../assets/newsletter/term3_week3.pdf"
// import term3_week4 from "../../assets/newsletter/term3_week4.pdf"
// import term3_week5 from "../../assets/newsletter/term3_week5.pdf"
// import term3_week6 from "../../assets/newsletter/term3_week6.pdf"
// import term3_week7 from "../../assets/newsletter/term3_week7.pdf"
// import term3_week8 from "../../assets/newsletter/term3_week8.pdf"
// import term3_week9 from "../../assets/newsletter/term3_week9.pdf"
// import term3_week10 from "../../assets/newsletter/term3_week10.pdf"
//
// import term4_week1 from "../../assets/newsletter/term4_week1.pdf"
// import term4_week2 from "../../assets/newsletter/term4_week2.pdf"
// import term4_week3 from "../../assets/newsletter/term4_week3.pdf"
// import term4_week4 from "../../assets/newsletter/term4_week4.pdf"
// import term4_week5 from "../../assets/newsletter/term4_week5.pdf"
// import term4_week6 from "../../assets/newsletter/term4_week6.pdf"
// import term4_week7 from "../../assets/newsletter/term4_week7.pdf"
// import term4_week8 from "../../assets/newsletter/term4_week8.pdf"
// import term4_week9 from "../../assets/newsletter/term4_week9.pdf"
import { useEffect } from "react";
import { useState } from "react";

const newsletterListTermOne = [

    // {
    // name: "Week 1",
    // path: term1_week1
    // },
    {
    name: "Week 2",
    path: term1_week2
    },
    // {
    // name: "Week 3",
    // path: term1_week3
    // },
    {
    name: "Week 4",
    path: term1_week4
    },  {
    name: "Week 5",
    path: term1_week5
    },
    {
    name: "week 6",
    path: term1_week6
    },
    // {
    // name: "Week 7",
    // path: term1_week7
    // },

    {
    name: "Week 8",
    path: term1_week8
    },
    {
    name: "Week 9",
    path: term1_week9
    },
    // {
    // name: "Week 10",
    // path: term1_week10
    // }
]

const newsletterListTermTwo = [
    // {
    //     name: "Week 1",
    //     path: term2_week1
    // },
    // {
    //     name: "Week 2",
    //     path: term2_week2
    // },  {
    //     name: "Week 3",
    //     path: term2_week3
    // },   {
    //     name: "Week 4",
    //     path: term2_week4
    // },
    // {
    //     name: "Week 5",
    //     path: term2_week5
    // },
    // {
    //     name: "Week 6",
    //     path: term2_week6
    // },
    // {
    //     name: "Week 7",
    //     path: term2_week7
    // },  {
    //     name: "Week 8",
    //     path: term2_week8
    // },   {
    //     name: "Week 9",
    //     path: term2_week9
    // },  {
    //     name: "Week 10",
    //     path: term2_week10
    // }
]

const newsletterListTermThree = [
    // {
    //     name: "Week 1",
    //     path: term3_week1
    // },
    // {
    //     name: "Week 2",
    //     path: term3_week2
    // },
    // {
    //     name: "Week 3",
    //     path: term3_week3
    // },  {
    //     name: "Week 4",
    //     path: term3_week4
    // },
    // {
    //     name: "Week 5",
    //     path: term3_week5
    // },
    // {
    //     name: "Week 6",
    //     path: term3_week6
    // },
    // {
    //     name: "Week 7",
    //     path: term3_week7
    // },
    // {
    //     name: "Week 8",
    //     path: term3_week8
    // },
    // {
    //     name: "Week 9",
    //     path: term3_week9
    // },
    // {
    //     name: "Week 10",
    //     path: term3_week10
    // }
]

const newsletterListTermFour = [
    // {
    //     name: "Week 1",
    //     path: term4_week1
    // },  {
    //     name: "Week 2",
    //     path: term4_week2
    // },
    // {
    //     name: "Week 3",
    //     path: term4_week3
    // },
    // {
    //     name: "Week 4",
    //     path: term4_week4
    // },
    // {
    //     name: "Week 5",
    //     path: term4_week5
    // },
    // {
    //     name: "Week 6",
    //     path: term4_week6
    // },  {
    //     name: "Week 7",
    //     path: term4_week7
    // }, {
    //     name: "Week 8",
    //     path: term4_week8
    // },  {
    //     name: "Week 9",
    //     path: term4_week9
    // }
]



const Newsletters = () => {

const [stateTermOne, setStateTermOne] = useState(false)
const [stateTermTwo, setStateTermTwo] = useState(false)
const [stateTermThree, setStateTermThree] = useState(false)
const [stateTermFour, setStateTermFour] = useState(false)

  return (
    <div className="container">
        <ContentWrapper sidebar={false} narrow={true}>
          <div className={"content"}>
            <PageTitle title="Newsletters" />
            <ol className="rounded-list">
                <li className="termTitle termOne" onClick={() => setStateTermOne(!stateTermOne)}><a>Term 1</a> 
                    <ol>
                        {
                         stateTermOne && newsletterListTermOne.map((newsletter) => (
                            <li key={newsletter.path}><a target="_blank" href={newsletter.path}>{newsletter.name}</a></li>
                        ))
                        }
                    </ol>
                </li>
                {/*<li className="termTitle" onClick={() => setStateTermTwo(!stateTermTwo)}><a>Term 2</a>*/}
                {/*    <ol>*/}
                {/*        {*/}
                {/*        stateTermTwo && newsletterListTermTwo.map((newsletter) => (*/}
                {/*            <li key={newsletter.path}><a target="_blank" href={newsletter.path}>{newsletter.name}</a></li>*/}
                {/*        ))*/}
                {/*        }*/}
                {/*    </ol>*/}
                {/*</li>*/}
                {/*<li className="termTitle" onClick={() => setStateTermThree(!stateTermThree)}><a>Term 3</a>*/}
                {/*    <ol>*/}
                {/*        {*/}
                {/*        stateTermThree && newsletterListTermThree.map((newsletter) => (*/}
                {/*            <li key={newsletter.path}><a target="_blank" href={newsletter.path}>{newsletter.name}</a></li>*/}
                {/*        ))*/}
                {/*        }*/}
                {/*    </ol>*/}
                {/*</li>*/}
                {/*<li className="termTitle" onClick={() => setStateTermFour(!stateTermFour)}><a>Term 4</a>*/}
                {/*    <ol>*/}
                {/*        {*/}
                {/*        stateTermFour && newsletterListTermFour.map((newsletter) => (*/}
                {/*            <li key={newsletter.path}><a target="_blank" href={newsletter.path}>{newsletter.name}</a></li>*/}
                {/*        ))*/}
                {/*        }*/}
                {/*    </ol>*/}
                {/*</li>*/}
            </ol>
          </div>
        </ContentWrapper>
      </div>
  );
};

export default Newsletters;
